//JQuery Module Pattern

(function ($) {

  function showTel() {
    $('.box-tel').click(function () {
      $('.show').hide();
      $('.hidden').show();
      $('.box-tel').addClass('clicked');
    });
  }

  /**
   * Funzione per Owl Carousel
   */

  function startOwl(selector) {
    $(selector).each(function (arguments) {
      var owl = $(this);
      var params = {
        nav: owl.data('owl-navigation'),
        navSpeed: owl.data('owl-slide-speed'),
        autoplay: owl.data('owl-autoplay'),
        autoplayTimeout: owl.data('owl-autoplay-timeout'),
        autoplaySpeed: owl.data('owl-autoplay-speed'),
        dots: owl.data('owl-dots'),
        dotsSpeed: owl.data('owl-dots-speed'),
        loop: owl.data('owl-loop'),
        center: owl.data('owl-center'),
        autoWidth: owl.data('owl-auto-width'),
        autoHeight: false,
        lazyLoad: true,
        animateOut: owl.data('owl-animation-out'),
        animateIn: owl.data('owl-animation-in'),
        navText: [
          "<i class='icon-edt-arrow-left-2'></i>",
          "<i class='icon-edt-arrow-right-2'></i>"
        ]
      };

      if (owl.data('owl-nav-prev') && owl.data('owl-nav-next')) {
        params['navText'] = [
          owl.data('owl-nav-prev'),
          owl.data('owl-nav-next')
        ];
      }

      if (owl.data('owl-single-item')) {
        params['items'] = 1;
      } else {
        params['responsive'] = {
          0: {
            items: owl.data('owl-items-xs'),
          },
          768: {
            items: owl.data('owl-items-sm'),
          },
          992: {
            items: owl.data('owl-items-md'),
          },
          1200: {
            items: owl.data('owl-items-lg'),
          },
          1440: {
            items: owl.data('owl-items-xl'),
          }
        };
      }

      owl.owlCarousel(params);

      if (owl.data('owl-prev')) {
        $(owl.data('owl-prev')).click(function () {
          owl.trigger('prev.owl.carousel');
        });
      }

      if (owl.data('owl-next')) {
        $(owl.data('owl-next')).click(function () {
          owl.trigger('next.owl.carousel');
        });
      }
    });
  }

  /**
   * Funzioni per PhotoSwiper
   */

  function addImageSizeForPhotoSwiper() {
    var thumbs = $('img[data-big-size]');
    thumbs.each(function (index) {
      if ($(this).parent('a').length > 0) {
        var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
        if (isImageLink) {
          big_size = $(this).data('big-size');
          link = $(this).parent('a');
          link.attr('data-size', big_size);
          link.wrap('<div class="single-image"></div>');
        }
      }
    });
  }

  function initPhotoSwiper(selector) {
    var galleryItems = $(selector);
    var pswpItems = [];

    galleryItems.each(function (index) {
      var dimensions = $(this).find('a').data('size').split('x');
      var width = parseInt(dimensions[0], 10);
      var height = parseInt(dimensions[1], 10);

      myImage = {
        src: $(this).find('a').attr('href'),
        w: width,
        h: height,
        msrc: $(this).find('img').attr('src')
      };

      pswpItems.push(myImage);
    });


    galleryItems.each(function (index) {
      $(this).click(function (evt) {
        evt.preventDefault();
        openPhotoSwipe(pswpItems, index);
      });
    });
  }

  function openPhotoSwipe(galleryItems, index) {
    var options = {
      index: index
    };
    var pswpElement = $('.pswp')[0];

    var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
    gallery.init();
  }

  function closePhotoswipe() {

    $('.pswp__button--close').click(function () {
      if ($('.pswp').hasClass('pswp--open')) {
        $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
      }
    });

    $(window).scroll(function () {
      if ($('.pswp').hasClass('pswp--open')) {
        $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
      }
    });
  }

  /**
 * Funzione per lo scroll morbido tra ancore interne
 */

  function smoothScroll() {
    $("a[href*='#']").not("[href='#'],[data-toggle]").click(function () {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {

          if (target.attr('data-paddingTopScroll')) {
            var adjust = target.attr('data-paddingTopScroll');
          }
          else {
            var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
          }

          $('html, body').animate({
            scrollTop: target.offset().top - adjust
          }, 1000);

          return false;
        }
      }
    });
  }

  /**
   * Funzione per l'attivazione del menu responsive
   */

  function responsiveMenu(viewportSize) {
    $('.main-navigation').find('a[href="#"]').each(function () {
      $(this).click(function (event) {
        event.preventDefault();
      });
    });

    if ($(window).width() <= viewportSize) { // se minore o uguale a 1199px di default
      var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");

      $('.main-navigation').find('a[href="#"]').each(function () {
        $(this).click(function (event) {
          $('ul.sub-menu').removeClass('open');
          $(this).next().toggleClass('open');
        });
      });

      $("#js-menu-offcanvas-button-open").click(function () {
        menuPrimaryOffcanvas.addClass("offcanvas-open");
      });

      $("#js-menu-offcanvas-button-close").click(function () {
        menuPrimaryOffcanvas.removeClass("offcanvas-open");
      });
    }
  }

  /**
	 * Funzione per nascondere e visualizzare la testata allo scroll della pagina
	 */

  function scrollHeader() {
    $('#js-header').scrollHide({
      scrollDelta: 10,
      scrollOffset: 140,
      scrollClass: 'scrh-header',
      scrollClassHide: 'scrh-hide',
      onScroll: function (context, msg, currentTop) {
        if (currentTop !== 0) {
          $('.menu-offcanvas').removeClass('offcanvas-open');
        }

        if (currentTop === 0) {
          $('.menu-offcanvas').removeClass('offcanvas-open');
        }

      }
    });
  }

  function toConsole(string) {
    if ((typeof window.console == "undefined")) {
      alert(string);
    } else console.log(string);
  }

  function $j(argument) {
    var result = jQuery(argument);
    if (result.length === 0) return null;
    else return result;
  }

  $j(document).ready(function () {

    showTel();
    startOwl('.owl-carousel');
    addImageSizeForPhotoSwiper();
    closePhotoswipe();
    smoothScroll();

    responsiveMenu(1199);

    // $('#feedModal').on('hidden.bs.modal', function (e) {
    //   $(this).removeData('bs.modal');
    //   $(".modal-content", this).empty();
    // });

    // $('.nav-item').click(function () {
    //   console.log($(this));
    //   $(this).addClass('active');
    // });

    if ($(window).width() < 1199) {
      scrollHeader();
    }

    $('.menu-item a').click(function () {
      $('.menu-item').not(this).each(function () {
        $(this).removeClass('current-menu-item');
      });

      $(this).parent().addClass('current-menu-item');
    });

    if ($('.box-tel').length) {
      $(window).scroll(function () {
        if ($(window).scrollTop() != 0) {
          $('.box-tel').fadeOut('fast', function () {
            $('.box-quote').fadeIn('fast').css("display", "block");
          });
        } else {
          $('.box-quote').fadeOut('fast', function () {
            $('.box-tel').fadeIn('fast');
          });
        }
      });
    }
    else {
      $('.box-quote').css("display", "block");
    }

    $('.gform_wrapper form').each(function () {
      $(this).checkEditaForm();
    });


    $(window).scroll(function () {
      if ($(window).scrollTop() != 0) {
        $('.menu-container').addClass('scroll');
      }
      else {
        $('.menu-container').removeClass('scroll');

        $('.menu-item').each(function () {
          $(this).removeClass('current-menu-item');
        });
      }
    });

    $('.box-quote').click(function () {
      $('.menu-item').each(function () {
        $(this).removeClass('current-menu-item');
      });
    });

  });

  $j(window).on('load', function () {
    initPhotoSwiper(".main-gallery .gallery-item");
  });

  $j(window).scroll(function () {

  });

})(jQuery);